.login {
  background-color: #fff;
  padding: 40px;
  border: 1px solid var(--color-stroke);
  width: 40%;
}

.login-input {
  display: flex;
  border: 1px solid var(--color-border);
  border-color: #069964;
  padding: 10px 15px;
  margin: 20px 0px;
  transition: all 0.5s ease-in-out;
}

.login-input.login-input-focus {
  border: 1px solid var(--color-primary);
  border-color: #069964;
}

.login-input:hover {
  border-color: #069964;
  border-right-width: 1px !important;
}

.login-input.login-input-danger {
  border: 1px solid var(--color-danger);
}

.login-input > input {
  width: 100%;
  border: none;
  margin-left: 5px;
}

.login-input > input:focus {
  outline: none;
}

.danger-text {
  color: var(--color-danger);
  margin-top: -20px;
  display: block;
}
