.form-group {
  margin-bottom: 20px;
}

.avatar-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-text {
  color: var(--color-danger);
}

.is-error {
  border-color: var(--color-danger);
}

.permission {
  display: flex;
  margin: 20px 0px;
}

.permission-checkbox {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.ant-input:hover {
  border-color: #069964;
  border-right-width: 1px !important;
}

.ant-input:focus {
  border-color: #069964;
  /* border-right-width: 1px !important; */
}

.ant-switch:focus {
  background-color: #069964;
}
