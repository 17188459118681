.kelola-sidebar {
  display: flex;
  background-color: var(--color-primary);
  padding: 20px 30px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 10px 0px 14px -8px rgba(44, 82, 130, 0.1);
  min-height: 100vh;
  z-index: 2;
}

.kelola-sidebar ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.kelola-sidebar ul > li {
  margin: 50px 0px 50px 0px;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease-in;
}

.kelola-sidebar ul > li.kelola-sidebar-active,
.kelola-sidebar ul > li:hover {
  background-color: #fa6506;
}

.kelola-sidebar ul > li > span {
  color: #fff;
  font-size: 1.4em;
}
