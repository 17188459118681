@import "~antd/dist/antd.css";
body {
  font-family: "Nunito", sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-primary);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-secondary);
}

.mr-5 {
  margin-right: 5px;
}

.breadcumb {
  list-style: none;
  padding: 0px;
  margin: 10px 0px;
  display: flex;
}

.breadcumb > li,
.breadcumb > li > a {
  color: var(--color-secondary);
}

.breadcumb > li.active {
  color: var(--color-primary);
}

.breadcumb > li:not(:last-child)::after {
  font-family: "Material Design Icons";
  content: "\F0FDF";
  margin: 0px 5px;
}

.mt-40 {
  margin-top: 40px;
}

.pointer {
  cursor: pointer;
}

.mr-5 {
  margin-right: 5px;
}

.mb-20 {
  margin-bottom: 20px;
}

.text-muted {
  color: var(--color-secondary);
}

.text-right {
  text-align: right;
}
.ant-button {
  background-color: #069964;
}

.ant-input:hover {
  border-color: #069964;
  border-right-width: 1px !important;
}

.ant-input:focus {
  border-color: #069964;
  /* border-right-width: 1px !important; */
}

.ant-btn-primary {
  background-color: #069964;
  border-color: #069964;
}

.ant-btn-primary:hover {
  background-color: #069964;
  border-color: #069964;
}

.ant-btn:hover {
  border-color: #069964;
  color: white;
}

.ant-modal-confirm .ant-modal-confirm-btns button + button {
  background-color: #069964;
  border-color: #069964;
  color: white;
}

.ant-input-wrapper ant-input-group:focus {
  border-color: #069964;
  /* border-right-width: 1px !important; */
}

.ant-input-search:hover {
  border-color: #069964;
  color: white;
}
.ant-input-search:focus {
  border-color: #069964;
  /* border-right-width: 1px !important; */
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  > .ant-input-wrapper
  .ant-input-search-button:not(.ant-btn-primary)
  :hover {
  border-color: #069964;
}
.input:focus {
  outline: none !important;
  border: 1px solid #069964;
  box-shadow: 0 0 10px #719ece;
}

.ant-picker:hover {
  border-color: #069964;
  border-right-width: 1px !important;
}

.ant-picker:focus {
  border-color: #069964;
  /* border-right-width: 1px !important; */
}
