.header {
  font-size: 6em;
  color: var(--color-secondary);
  margin: 0;
  line-height: normal;
}

.subheader {
  color: var(--color-black);
  margin-bottom: 30px;
}
