.nestable-item.is-dragging::after, .nestable-item.is-dragging:before {
    background: var(--color-active) !important;
    border: 2px dashed var(--color-primary) !important;
}

.nestable-item-name {
    background-color: #fff;
    padding: 10px;
    cursor: grab;
}

.menu-list {
    width: 100%;
}

.menu-list > ol > li  div.nestable-item-name {
    border: 2px solid var(--color-stroke)
}

.menu-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu-disabled {
    opacity: .5;
}

.menu-disabled > div {
    text-decoration: line-through;
}