.kelola-layout {
  display: flex;
  /* min-height: calc(100vh); */
  overflow-x: hidden;
}

.ant-btn:not(.ant-btn-primary):hover {
  border-color: var(--color-primary) !important;
  color: var(--color-primary) !important;
  opacity: 0.8;
}
.kelola-navbar-sidebar-wrapper {
  width: 100%;
}