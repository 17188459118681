.content {
  background-color: #fff;
  padding: 20px;
  height: 100%;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  margin-left: 90px;
  margin-right: 55px;
  /* width: 100%; */
}

#customers td,
#customers th {
  padding: 8px;
  text-align: center;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #04aa6d;
  color: white;
}
