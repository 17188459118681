.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #069964;
  border-right-width: 1px !important;
}

.form-group {
  margin-bottom: 20px;
}

.avatar-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-text {
  color: var(--color-danger);
}

.is-error {
  border-color: var(--color-danger);
}

.permission {
  display: flex;
  margin: 20px 0px;
}

.permission-checkbox {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.ant-select
  ant-select-single
  ant-select-show-arrow
  ant-select-open
  ant-select-show-search {
  border-color: #069964;
}

.ant-select-selection-item {
  border-color: #069964;
}

.ant-select
  ant-select-single
  ant-select-allow-clear
  ant-select-show-arrow
  ant-select-open
  ant-select-show-search {
  border-color: chartreuse;
}

.ant-select {
  border-color: #069964;
}

.ant-select-selection-search {
  border-color: #069964;
  width: 300px;
}
.ant-select ant-select-single ant-select-show-arrow ant-select-open {
  border-color: #069964;
}
