/* .ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: #069964;
}

.ant-btn-primary {
  background-color: #069964;
  border-color: #069964;
}

.ant-btn-primary:hover {
  background-color: #069964;
  border-color: #069964;
}

.ant-btn:hover {
  border-color: #069964;
  color: #069964;
}
.input.ant-input {
  border-color: #069964;
}

.ant-input-group:hover {
  border-color: #069964;
} */

.ant-table-content .warning-stock {
  background-color: red;
}

.ant-table-content .no-detail {
  background-color: #FFC107;
}
.ant-table-content .ant-table-row.warning-stock:hover {
  background-color: red;
  color: red;
}